.error-page-container {
  #bb-logo {
    height: 35px;
    margin: 20px 0;
  }
  #error-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 50px - 75px);
  }
  .page-content {
    padding: 0 20px;
    text-align: center;
    display: block;
  }
  .footer {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    bottom: 0;
    position: relative;

    a {
      color: #fff;
    }

    &.text-muted {
      color: #999999;
    }
  }
  #particles-js {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }
  .primary-button {
    min-width: 150px;
    padding: 0 20px;
    background-color: white;
    border-radius: 2px;
    color: #1080f2;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 36px;
    margin: 0 auto;
    border: 2px solid white;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }
}